
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useFormErrors from '@/utils/validate';
import { ElForm, ElMessage } from 'element-plus';
import { usePayments, useAddCredit } from '@/composables/api';

export default {
  setup() {
    const { t } = useI18n();
    const formRef = ref<InstanceType<typeof ElForm> | null>(null);
    const { formErrors, bindFormItemError } = useFormErrors();

    const dialogFormVisible = ref(false);
    const { data, isLoading, isFetching } = usePayments({ page: ref(1), keyword: ref('') });
    const formValues = ref({
      gateway: '',
      credit: 0
    });

    const rules = {
      gateway: [{ required: true, message: t('Please select a gateway'), trigger: 'blur' }],
      credit: [{ required: true, message: t('Please input the credit'), trigger: 'blur' }]
    };

    const gatewayOptions = ref([{ label: 'Lotus', value: 'lotus' }]);
    const openDialog = () => {
      dialogFormVisible.value = true;
    };

    const closeDialog = () => {
      dialogFormVisible.value = false;
      formValues.value = { gateway: '', credit: 0 }; // Reset form values
    };

    const { mutate: addCredit } = useAddCredit();

    const submitForm = async() => {
      formErrors.value = {};

      if (!formRef.value) {
        console.error('The form reference is undefined.');
        return;
      }

      try {
        await formRef.value.validate();
        addCredit(
          { data: formValues.value },
          {
            onSuccess: () => {
              ElMessage.success({
                message: t('success!'),
                type: 'success'
              });
              closeDialog();
            },
            onError: (error: any) => {
              ElMessage.error({
                message: error.response?.data.message || t('An error occurred'),
                type: 'error'
              });
              formErrors.value = error.response?.data.errors;
            }
          }
        );
      } catch (validateError) {
        console.error('Validation failed:', validateError);
      }
    };

    return {
      openDialog,
      closeDialog,
      submitForm,
      bindFormItemError,
      t,
      rules,
      formValues,
      formErrors,
      data,
      isLoading,
      isFetching,
      gatewayOptions,
      dialogFormVisible,
      formRef
    };
  }
};
